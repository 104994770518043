"use strict";

!function (o) {
  o.topbutton = function (t) {
    var n = {
      htlm: "Top",
      css: void 0,
      scrollSpeed: 150,
      scrollAndShow: !1
    };

    for (var key in t) {
      n[key] = t[key];
    }

    var e = {
      width: "45px",
      height: "45px",
      background: "#343a40",
      color: "#fff",
      right: "15px",
      bottom: "15px",
      border: "2px solid #495057",
      "border-radius": "5px",
      "font-size": "17px"
    };

    if (void 0 != n.css) {
      var r = {},
          i = t.css.split(";").filter(function (o) {
        return "" != o;
      });

      for (var _key in i) {
        var l = i[_key].split(":"),
            p = l[0].replace(/(\s*)/g, ""),
            s = l[1].replace(/(\s*)/g, "");

        r[p] = s;
      }

      for (var _key2 in r) {
        e[_key2] = r[_key2];
      }
    }

    var d = "";

    for (var _key3 in e) {
      d += _key3 + ":" + e[_key3] + "; ";
    }

    var c = "";

    if (n.scrollAndShow) {
      c = "display:none;";
      var a = !0;
      o(window).scroll(function () {
        o(this).scrollTop() > 100 ? a || (o("#topButton").fadeIn(), a = !0) : a && (o("#topButton").fadeOut(), a = !1);
      }), o(window).scrollTop() > 100 && (c = "display:block;");
    }

    var f = '<button type="button" id="topButton" style="' + c + "position:fixed; border:none; outline:none; overflow:hidden; cursor:pointer; " + d + '">' + n.htlm + "</button>";
    o("body").append(f), o("#topButton").click(function () {
      o("html, body").animate({
        scrollTop: 0
      }, parseInt(n.scrollSpeed));
    });
  };
}(jQuery);
