$(function () {

    var owl = $(".js-brand-owlcarousel");

    owl.owlCarousel({
        items: 1,
        loop: true,
        nav: true,
        autoplay: true, 
        autoplayTimeout: 5000,
        smartSpeed: 3000,
        animateOut: "fadeOut",
        responsive: {
            0: {
                autoHeight: true
            },
            768: {
                autoHeight: true
            },
        }
    });

    var infoCaruselBox = $('.js-story-owlcarousel');
    infoCaruselBox.addClass('owl-carousel').owlCarousel({
        margin: 50,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: true,
        loop: true,
        nav: true,
        dots: true,
        autoWidth: true,
        stagePadding: 60,
        items: 2,
        responsive: {
            0: {
                items: 1,
                margin: 16,
            },
            768: {
                items: 1
            },
            980: {
                items: 1
            },
            1950: {
                stagePadding: 60,
                items: 2
            },
            4050: {
                stagePadding: 60,
                items: 2
            }
        }
    });
});
